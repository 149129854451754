import React from 'react';
import ReactDOM from 'react-dom';
import './styles/desktop.css';
import twitterLogo from '../Assets/Images/twitter-logo.png';
import instagramLogo from '../Assets/Images/instagram-logo.png';
import twitterButton from '../Assets/Images/twitter-button.png';
import instagramButton from '../Assets/Images/instagram-button.png';
import separationLine from '../Assets/Images/Linea.png';
import mainGif from '../Assets/Images/butt-gif.gif';
import mainText from '../Assets/Images/a-butt-in-disguise.png';
import buttLogo from '../Assets/Images/butt-logo.png';
import primaryButton from '../Assets/Images/primary-button.png';
import secondaryButton from '../Assets/Images/secondary-button.png';


const Desktop = ({ handleButton, toggleButton }) => {
	
	return (
		<div className="body">

			<div className="header">
				<div className="butt-logo">
					<a href="#">
						<img className="logo" src={buttLogo} alt="twitter" width="54" height="54"/>
					</a>
				</div>
				<div className="socials">
					
					<a href="https://www.instagram.com/" target="_blank">
						<img className="logo" src={instagramLogo} alt="instagram" width="40" height="40" />
					</a>
					<a href="https://twitter.com/ButtInDisguise" target="_blank">
						<img className="logo" src={twitterLogo} alt="twitter" width="40" height="40"/>
					</a>
					
				</div>
			</div>

			<div className="main-div">
				<div className="main">
					<div className="left-div">
						<img className="main-gif" src={mainGif} alt="Main gif" width="450" height="450" />
					</div>
					<div className="right-div">
						<img className="main-text" src={mainText} alt="Main Text" width="436" height="153"/>
						<p className="description">The Buttverse is getting ready for the craziest <br/> cosplay party ever!</p>
						{
							toggleButton == false ? 
								<img className="main-button" src={primaryButton} alt="Dropping february 2022" width="368" height="51.6" onClick={() => handleButton()} role="button" />
							:
								<img className="main-button" src={secondaryButton} alt="not yet, butt soon!" width="368" height="51.6" />
						}
					</div>
				</div>
				<div className="line">
					<img src={separationLine} alt="line" width="975" height="4.9"/>
				</div>
			</div>

			<div className="bottom">
				<div>
					<a href="https://twitter.com/ButtInDisguise" target="_blank">
						<img className="social-buttons" src={twitterButton} alt="twitter" width="163" height="39.7"/>
					</a>
				</div>
				<div>
					<a href="https://www.instagram.com/" target="_blank">
						<img className="social-buttons" src={instagramButton} alt="instagram" width="163" height="39.7" />
					</a>
				</div>
			</div>

		</div>
	)
} 

export default Desktop;