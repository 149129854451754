import React from 'react';
import ReactDOM from 'react-dom';
import { useState, useLayoutEffect } from 'react';
import Desktop from './Devices/Desktop.js';
import Tablet from './Devices/Tablet.js';
import Mobile from './Devices/Mobile.js';

const App = () => {

	const [ toggleButton, setToggleButton ] = useState(false);
	const [ device, setDevice ] = useState("desktop");
	const [size, setSize] = useState([0, 0]);

	//detect screen width and display different component
	useLayoutEffect(()=>{
		if(window.innerWidth >= 980) {
			setDevice("desktop")
		} 
		if(window.innerWidth >= 520 && window.innerWidth < 980) {
			setDevice("tablet")
		}
		if(window.innerWidth < 520) {
			setDevice("mobile")
		}
	}, [size, setSize])

	useLayoutEffect(() => {

		const updateSize = () => {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener('resize', updateSize);
		updateSize();

	}, [])
	

	// ----- interactive button ------
	const buttonTimer = () => {
		setToggleButton(false)
	}

	const handleButton = () => {
		setToggleButton(true);
		setTimeout(buttonTimer, 3500);
	}
	// -------------------------------

	if(device == "desktop") {
		return (
			<Desktop handleButton={handleButton} toggleButton={toggleButton} />
		)	
	} 

	if(device == "tablet") {
		return (
			<Tablet handleButton={handleButton} toggleButton={toggleButton} />
		)
	}

	if(device == "mobile") {
		return (
			<Mobile handleButton={handleButton} toggleButton={toggleButton} />
		)
	}
}

export default App;