import React from 'react';
import ReactDOM from 'react-dom';
import './styles/mobile.css';
import twitterLogo from '../Assets/Images/twitter-logo.png';
import instagramLogo from '../Assets/Images/instagram-logo.png';
import twitterButton from '../Assets/Images/twitter-button.png';
import instagramButton from '../Assets/Images/instagram-button.png';
import separationLine from '../Assets/Images/Linea.png';
import mainGif from '../Assets/Images/butt-gif.gif';
import mainText from '../Assets/Images/a-butt-in-disguise.png';
import buttLogo from '../Assets/Images/butt-logo.png';
import primaryButton from '../Assets/Images/primary-button.png';
import secondaryButton from '../Assets/Images/secondary-button.png';
import tabletLine from '../Assets/Images/line-t.png';

const Mobile = ({ handleButton, toggleButton }) => {

	return (
		<div className="m-body">

			<div className="m-header">
				<div className="m-butt-logo-div">
					<a href="#">
						<img className="m-butt-logo" src={buttLogo} alt="twitter" width="30" height="30"/>
					</a>
				</div>
				<div className="m-socials">
					
					<a href="https://www.instagram.com/" target="_blank">
						<img className="m-logo" src={instagramLogo} alt="instagram" width="25" height="25" />
					</a>
					<a href="https://twitter.com/ButtInDisguise" target="_blank">
						<img className="m-logo" src={twitterLogo} alt="twitter" width="25" height="25"/>
					</a>
					
				</div>
			</div>

			<div className="m-main-div">
				<div className="m-main-text-div">
					<img className="m-main-text" src={mainText} alt="Main Text" width="267.92" height="108.19"/>
				</div>
				<div className="m-main">
					<div className="m-gif-div">
						<img className="m-main-gif" src={mainGif} alt="Main gif" width="270" height="270" />
					</div>
					<div className="m-line">
						<img src={tabletLine} alt="line" width="265" height="2"/>
					</div>
				</div>
			</div>

			<div className="m-text-div">
				<p className="m-description">The Buttverse is getting ready <br /> for the craziest cosplay party ever!</p>
				{
					toggleButton == false ? 
						<img className="m-main-button" src={primaryButton} alt="Dropping february 2022" width="268" height="37" onClick={() => handleButton()} role="button" />
					:
						<img className="m-main-button" src={secondaryButton} alt="not yet, butt soon!" width="268" height="37" />
				}
			</div>

			<div className="m-bottom">
				<div>
					<a href="https://twitter.com/ButtInDisguise" target="_blank">
						<img className="m-social-buttons" src={twitterButton} alt="twitter" width="128" height="30"/>
					</a>
				</div>
				<div>
					<a href="https://www.instagram.com/" target="_blank">
						<img className="m-social-buttons" src={instagramButton} alt="instagram" width="128" height="30" />
					</a>
				</div>
			</div>
			
		</div>
	)
}

export default Mobile;