import React from 'react';
import ReactDOM from 'react-dom';
import './styles/tablet.css';
import twitterLogo from '../Assets/Images/twitter-logo.png';
import instagramLogo from '../Assets/Images/instagram-logo.png';
import twitterButton from '../Assets/Images/twitter-button.png';
import instagramButton from '../Assets/Images/instagram-button.png';
import separationLine from '../Assets/Images/Linea.png';
import mainGif from '../Assets/Images/butt-gif.gif';
import mainText from '../Assets/Images/a-butt-in-disguise.png';
import buttLogo from '../Assets/Images/butt-logo.png';
import primaryButton from '../Assets/Images/primary-button.png';
import secondaryButton from '../Assets/Images/secondary-button.png';
import tabletLine from '../Assets/Images/line-t.png';

const Tablet = ({ handleButton, toggleButton }) => {

	return (
		<div className="t-body">

			<div className="t-header">
				<div className="t-butt-logo-div">
					<a href="#">
						<img className="t-butt-logo" src={buttLogo} alt="twitter" width="54" height="54"/>
					</a>
				</div>
				<div className="t-socials">
					
					<a href="https://www.instagram.com/" target="_blank">
						<img className="t-logo" src={instagramLogo} alt="instagram" width="40" height="40" />
					</a>
					<a href="https://twitter.com/ButtInDisguise" target="_blank">
						<img className="t-logo" src={twitterLogo} alt="twitter" width="40" height="40"/>
					</a>
					
				</div>
			</div>

			<div className="t-main-div">
				<div className="t-main-text-div">
					<img className="t-main-text" src={mainText} alt="Main Text" width="401" height="160"/>
				</div>
				<div className="t-main">
					<div className="t-gif-div">
						<img className="t-main-gif" src={mainGif} alt="Main gif" width="397" height="397" />
					</div>
					<div className="t-line">
						<img src={tabletLine} alt="line" width="500" height="5"/>
					</div>
				</div>
			</div>

			<div className="t-text-div">
				<p className="t-description">The Buttverse is getting ready <br /> for the craziest cosplay party ever!</p>
				{
					toggleButton == false ? 
						<img className="t-main-button" src={primaryButton} alt="Dropping february 2022" width="398" height="56" onClick={() => handleButton()} role="button" />
					:
						<img className="t-main-button" src={secondaryButton} alt="not yet, butt soon!" width="398" height="56" />
				}
			</div>

			<div className="t-bottom">
				<div>
					<a href="https://twitter.com/ButtInDisguise" target="_blank">
						<img className="t-social-buttons" src={twitterButton} alt="twitter" width="187" height="45"/>
					</a>
				</div>
				<div>
					<a href="https://www.instagram.com/" target="_blank">
						<img className="t-social-buttons" src={instagramButton} alt="instagram" width="187" height="45" />
					</a>
				</div>
			</div>
			
		</div>
	)
}

export default Tablet;
